import './logo.css';

export default function Logo() {
  return (
    <div className="logo">
      <div></div>
      <h1>QUIPU</h1>
    </div>
  );
}
