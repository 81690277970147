const friendship_data = [
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목10.jpeg' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목8.jpg' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목7.jpg' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목6.png' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목5.png' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목3.jpg' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목2.jpg' },
  { year: '2024', src: '/ActivityDetail-img/2024/FriendShip/친목1.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목15.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목14.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목13.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목12.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목11.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목10.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목9.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목8.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목7.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목6.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목5.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목3.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/2학기개강총회.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목2.jpg' },
  { year: '2023', src: '/ActivityDetail-img/2023/FriendShip/친목1.jpg' },
];

export default friendship_data;
