const study_data = [
  {
    year: '2024',
    title: '해커톤',
    period: '2024.07 - 2024.08',
    shortDescription: '생성형 AI를 활용한 서비스 개발',
    goal: '생성형 AI 기반 지적장애인 맞춤형 언어학습 서비스를 개발하여 해커톤에 출전합니다.',
    details: [
      '언어학습을 통해 지적장애인이 더 넓은 세상과 소통할 수 있도록 도와준다는 서비스에 대한 목표를 정합니다.',
      '서비스 소개',
      '1. 일상 대화와 공감형 대화 데이터를 학습한 생성형 AI를 사용합니다.',
      '2. 학습자의 음성을 텍스트로 인식하여 현실에 적용하기 쉬운 환경을 제공합니다.',
      '3. 대화의 흐름뿐만 아니라 발음에 점수를 매겨 시각적으로 성취를 이룰 수 있습니다.',
      '4. 마이페이지에서 학습자의 반복되는 대화 패턴과 피드백을 제공합니다.',
      '기술 스택: UX/UI 디자인(Figma), 프론트엔드(React.js), 백엔드(Spring)',
    ],
    links: {},
    leaders: ['이예나'],
    members: ['주재원', '김예영', '정윤하'],
    allMembers: ['주재원', '김예영', '정윤하'],
    mainimage: '/ActivityDetail-img/2024/Study/새싹해커톤1.png',
    allImgs: [
      '/ActivityDetail-img/2024/Study/새싹해커톤2.jpeg',
      '/ActivityDetail-img/2024/Study/새싹해커톤3.png',
      '/ActivityDetail-img/2024/Study/새싹해커톤4.png',
      '/ActivityDetail-img/2024/Study/새싹해커톤5.png',
    ],
  },
  {
    year: '2024',
    title: 'C언어 스터디',
    period: '2024.04 - 2024.06',
    shortDescription: 'C언어 입문 및 코드 리뷰',
    goal: 'C언어의 기본 개념과 문법을 공부합니다.',
    details: [
      '팀을 나누어 일주일에 한 번씩 대면 스터디를 진행합니다.',
      '코드업 C언어 기초 100제를 풀고 풀이를 공유 및 질의응답을 합니다.',
    ],
    links: {},
    leaders: ['이예나', '이제민'],
    members: ['전체 30명'],
    allMembers: ['전체 30명'],
    mainimage: '/ActivityDetail-img/2024/Study/C스터디1.png',
    allImgs: ['/ActivityDetail-img/2024/Study/C스터디1.png'],
  },
  {
    year: '2024',
    title: '리액트 스터디',
    period: '2024.03 - 2024.06',
    shortDescription: '프론트엔드 토이 프로젝트',
    goal: '프론트엔드 라이브러리인 리액트에 대한 개념을 공부하고 토이 프로젝트를 만듭니다.',
    details: [
      '1. 컴포넌트, 리액트 Hooks, 라우팅 등의 웹 개발에 필요한 기본적인 개념을 이해합니다.',
      '2. 갤러리와 블로그의 기능을 가진 토이 프로젝트를 만듭니다.',
    ],
    leaders: ['이예나'],
    members: ['김예영', '김준호', '박주현', '외 7명'],
    allMembers: [
      '김예영',
      '김준호',
      '박주현',
      '송승준',
      '안혁',
      '이채우',
      '임준용',
      '정재윤',
      '정지훈',
      '최진영',
    ],
    mainimage: '/ActivityDetail-img/2024/Study/리액트스터디1.png',
    allImgs: [
      '/ActivityDetail-img/2024/Study/리액트스터디2.jpeg',
      '/ActivityDetail-img/2024/Study/리액트스터디3.jpeg',
      '/ActivityDetail-img/2024/Study/리액트스터디4.png',
      '/ActivityDetail-img/2024/Study/리액트스터디5.png',
      '/ActivityDetail-img/2024/Study/리액트스터디6.png',
    ],
  },
  {
    year: '2024',
    title: '웹개발 입문 스터디',
    period: '2024.03 - 2024.06',
    shortDescription: '기획, 디자인, 개발 입문 프로젝트',
    goal: '웹 개발에 필요한 개발 지식을 학습하고 팀별로 작은 서비스를 개발합니다.',
    details: [
      '1. 웹 개발의 전반적인 프로세스에 대한 킥오프 진행',
      '2. 기획, 디자인, 프론트엔드, 백엔드팀 별 세미나 진행',
      '3. 각 팀별 강의를 듣고 실습을 하며 스터디 진행',
      '4. 스터디 내용을 바탕으로 기획-디자인-개발 단계별 실전 프로젝트 진행',
      '기술 스택: UX/UI 디자인(Figma), 프론트엔드(React.js), 백엔드(node.js)',
    ],
    leaders: ['이예나', '이제민'],
    members: ['전체 52명'],
    allMembers: ['전체 52명'],
    mainimage: '/ActivityDetail-img/2024/Study/웹개발입문1.png',
    allImgs: ['/ActivityDetail-img/2024/Study/웹개발입문2.png'],
  },
  {
    year: '2024',
    title: '코딩테스트 스터디',
    period: '2024.01 - 2024.02',
    shortDescription: '깃허브를 사용하여 백준 문제 리뷰',
    goal: '코딩테스트에 나오는 기본 개념들을 숙지하고 코드를 짜봅니다.',
    details: [
      '범위 : 자료구조, 정렬, 탐색, 그리디, 정수론, 그래프, 트리, 조합, 동적계획법, 기하',
      '1. 매주 개념 하나씩 노션에 정리',
      '2. 개념과 관련한 백준 5문제씩 풀어 깃허브에 업로드',
      '3. 메타버스를 활용하여 풀어온 5문제를 대표자가 리뷰하며 코드 소개',
      '4. 깃허브에 업로드 된 다른 사람의 코드를 PR을 이용하여 리뷰',
    ],
    links: {},
    leaders: ['이예나'],
    members: ['강형준', '김명준', '김예영', '외 1명'],
    allMembers: ['강형준', '김명준', '김예영', '이제민'],
    mainimage: '/ActivityDetail-img/2024/Study/코딩테스트스터디1.png',
    allImgs: ['/ActivityDetail-img/2024/Study/코딩테스트스터디2.jpg'],
  },
  {
    year: '2023',
    title: '퀴푸 메인 웹 개발',
    period: '2023.10 - 2024.02',
    shortDescription: '퀴푸의 첫 웹 개발 스터디',
    goal: '퀴푸를 대표하는 메인 웹을 제작합니다.',
    details: [
      '디자인, 프론트엔드, 백엔드 팀을 나누어 매주 1회 팀별 정기모임을 갖습니다.',
      '3개월 동안 인프런 및 유튜브 강의를 들으며 개발관련 내용을 숙지합니다.',
      '2개월 동안 노션에 개발일지를 쓰며 깃허브를 사용해 협업하며 개발을 진행합니다.',
    ],
    links: {
      '멤버 소개 및 개발일지':
        'https://quipu-dev.notion.site/main-website-c8661a4633804bfdaadb105c86d2df1e?pvs=4',
      깃허브: 'https://github.com/Quipu-Developers',
    },
    leaders: ['이예나'],
    members: ['김고은', '황민영', '박시현', '외 6명'],
    allMembers: [
      '김고은',
      '황민영',
      '박시현',
      '차준섭',
      '김은지',
      '이제민',
      '표성은',
      '박수연',
      '강형준',
    ],
    mainimage: '/ActivityDetail-img/2023/Study/퀴푸메인웹개발1.png',
    allImgs: [
      '/ActivityDetail-img/2023/Study/퀴푸메인웹개발2.jpg',
      '/ActivityDetail-img/2023/Study/퀴푸메인웹개발3.jpg',
      '/ActivityDetail-img/2023/Study/퀴푸메인웹개발4.jpg',
    ],
  },
  {
    year: '2023',
    title: 'C언어 스터디',
    period: '2023.10 - 2023.11',
    shortDescription: 'C언어 입문 및 코드 리뷰',
    goal: 'C언어의 기본 개념과 문법을 배웁니다',
    details: [
      "CodeUp 사이트에서 'C언어 기초 100제'를 범위를 나누어 매주 풀고 만나서 리뷰하는 방식으로 진행하였습니다.",
    ],
    links: {},
    leaders: ['서강훈'],
    members: ['이예나', '이정훈', '조영서', '외 2명'],
    allMembers: ['이예나', '이정훈', '조영서', '차원재', '최다영'],
    mainimage: '/ActivityDetail-img/2023/Study/C스터디1.png',
    allImgs: ['/ActivityDetail-img/2023/Study/C스터디2.png'],
  },
  {
    year: '2023',
    title: '전자장 및 전기회로 강연',
    period: '2023.09 - 2023.12',
    shortDescription: '전자장과 전기회로 기본 개념 강연',
    goal: '학부 과정에 필요한 핵심적인 전자장과 전기회로의 기본 개념을 배웁니다.',
    details: ['매주 강의실에서 수업을 듣고 질의응답을 받습니다.'],
    links: {},
    leaders: ['오병규'],
    members: ['전체'],
    allMembers: ['전체'],
    mainimage: '/ActivityDetail-img/2023/Study/전자장및전기회로1.png',
    allImgs: ['/ActivityDetail-img/2023/Study/전자장및전기회로2.png'],
  },
  {
    year: '2023',
    title: 'IT 공모전',
    period: '2023.08 - 2023.11',
    shortDescription: '대한전자공학과 IT창의 챌린지 공모전',
    goal: '무더운 여름 횡단보도 주변에 설치된 파라솔에 쿨링 포그 시스템을 접목하여, 획기적으로 주변 온도를 줄이는 스마트 쿨링 포그 파라솔 제작합니다.',
    details: [
      '2개월동안 어떤 아이디어를 설계해 구현할지 생각하고 이에 따른 플로우 차트를 구성해 교수님과 면담을 진행합니다.',
      '아이디어 선정 후 온도, 습도, 사람 인식 센서 및 clock에 대해 파트를 나눠 주 2-3회씩 정기적 모임을 갖습니다.',
      '1개월동안 맡은 파트에대해 data sheet 및 유튜브를 통해 설계할 회로에 대한 구상을 합니다. ',
      '회로 설계 후 breadboard에 해당 회로에 맞게 소자를 납땜합니다. (PCB기판 이용 시 가산점)',
      '설계한 회로를 이용한 시제품을 구성 후 발표자료를 만들고 대회장에서 시제품을 동작시키며 발표합니다.',
    ],
    links: {},
    leaders: ['김형민'],
    members: ['신교윤', '안태성', '정하연'],
    allMembers: ['신교윤', '안태성', '정하연'],
    mainimage: '/ActivityDetail-img/2023/Study/공모전1.png',
    allImgs: ['/ActivityDetail-img/2023/Study/공모전2.png'],
  },
  {
    year: '2023',
    title: '코딩테스트 스터디',
    period: '2023.06 - 2023.08',
    shortDescription: '백준 문제 코드 리뷰',
    goal: '기본 파이썬 문법을 익히고, 백준 문제를 풀며 코딩테스트에 대비합니다.',
    details: [
      '매주 동아리방에 모여 풀어온 10문제를 각자 리뷰하며 문제에 대한 접근 방식을 공유합니다.',
    ],
    links: {},
    leaders: ['모용'],
    members: ['이동현', '이예나', '하진혁'],
    allMembers: ['이동현', '이예나', '하진혁'],
    mainimage: '/ActivityDetail-img/2023/Study/코딩테스트스터디1.png',
    allImgs: ['/ActivityDetail-img/2023/Study/코딩테스트스터디2.png'],
  },
  {
    year: '2023',
    title: 'Python 강연',
    period: '2023.03 - 2023.06',
    shortDescription: 'python 입문 강연',
    goal: '수업과 실습을 통해 파이썬에 입문합니다.',
    details: [
      '파이썬 기초 문법을 배웁니다.',
      '파이썬과 관련한 터미널의 기본적인 사용방법을 배웁니다.',
    ],
    links: {},
    leaders: ['정욱재'],
    members: ['전체'],
    allMembers: ['전체'],
    mainimage: '/ActivityDetail-img/2023/Study/Python강연1.png',
    allImgs: ['/ActivityDetail-img/2023/Study/Python강연2.png'],
  },
  {
    year: '2023',
    title: 'Python 기초 스터디',
    period: '2023.02 - 2023.06',
    shortDescription: 'python 입문 및 활용',
    goal: '파이썬에 입문하고 간단한 파이썬 프로젝트를 만듭니다.',
    details: [
      '파이썬 유튜브 강의를 보고 문제를 풀며 서로 질의응답을 합니다.',
      '파이썬 스도쿠 오픈소스를 보며 파이썬 모듈에 대해 공부하고, 전체적인 동작 흐름을 분석합니다',
      'flask를 사용하여 기본적인 웹을 제작해봅니다.',
    ],
    links: {},
    leaders: ['이예나'],
    members: ['김형민', '남민우', '남현우', '외 5명'],
    allMembers: ['김형민', '남민우', '남현우', '최영채', '전한솔', '최다영', '표성은', '하진혁'],
    mainimage: '/ActivityDetail-img/2023/Study/Python기초스터디1.png',
    allImgs: [
      '/ActivityDetail-img/2023/Study/Python기초스터디2.png',
      '/ActivityDetail-img/2023/Study/Python기초스터디3.png',
    ],
  },
  {
    year: '2023',
    title: 'C++ 강연',
    period: '2022.12 - 2023.02',
    shortDescription: 'C++ 기본 문법 강연',
    goal: 'C++에 입문하고 객체 지향 프로그래밍에 대한 기초 지식을 배웁니다.',
    details: [
      '매주 스터디룸에서 C++에 관련한 문법을 배웁니다.',
      '수업과 관련한 코드 작성 과제를 풀고 해답을 얻으며 질의응답을 합니다.',
    ],
    links: {},
    leaders: ['최준하'],
    members: ['김시은', '김형민', '서진주', '외 8명'],
    allMembers: [
      '김시은',
      '김형민',
      '서진주',
      '이예나',
      '이학재',
      '이해리',
      '조승호',
      '차준섭',
      '최영채',
      '표성은',
      '하진혁',
    ],
    mainimage: '/ActivityDetail-img/2023/Study/Cpp스터디1.png',
    allImgs: ['/ActivityDetail-img/2023/Study/Cpp스터디2.png'],
  },
];

export default study_data;
