export const interview_data = [
  // 1기수
  [
    {
      img: '주재원.png',
      name: '주재원',
      title: '오늘 독립했어요 짱신남',
      introduction:
        '컴퓨터과학부 20학번 주재원입니다! 다양한 개발 분야를 접해보고 있고, 퀴푸에서는 백엔드 파트를 맡고 있습니다.',
      review: [
        '프론트, 디자인 분들과 협업 경험을 쌓을 수 있어서 의미있었습니다. 노드도 써볼 수 있어서 좋았어요.',
      ],
      project: ['🗂️포럼 - 백엔드'],
    },
    {
      img: '김예영.png',
      name: '김예영',
      title: '프론트엔드 짱',
      introduction:
        '안녕하세요! 퀴푸 개발팀에서 프론트엔드를 맡은 전자전기컴퓨터공학부 22학번 김예영입니다.',
      review: [
        '이번 활동으로 코딩을 거의 처음 접하게 되어 낯선 것이 정말 많았는데, 퀴푸의 웹개발 활동으로 방학동안 동방의 코딩 귀신이 될 수 있었습니다. 덕분에 코딩에 확실한 흥미가 생겼고 방학을 알차게 보낼 수 있었어요. 사랑해요 퀴푸 ♥',
      ],
      project: ['🗂️포럼 - 프론트엔드', '🧑‍💻메인웹 v2 - 프론트엔드'],
    },
    {
      img: '이채우.png',
      name: '이채우',
      title: '호호잇',
      introduction: '코딩 올라운더가 되고 싶은 이채우입니다 >_<',
      review: [
        '얻어가고 싶으면 많은 걸 얻어갈 수 있는 동아리라는 생각이 들었습니다. 더 많은 활동을 해서 더더 많은 것들을 얻어가고 싶어요~!',
      ],
      project: ['🛠️백오피스 - 프론트엔드', '📬메인웹 v2 - 프론트엔드'],
    },
    {
      img: '정윤하.png',
      name: '정윤하',
      title: '디자인 재밌어용~',
      introduction:
        '안녕하세요! 이번 퀴푸 웹개발 프로젝트에 기획/디자인으로 참여하게 된 21학번 정윤하입니다.',
      review: [
        '원래 디자인에 흥미만 있고 따로 공부를 안 하고 있었는데, 이번 프로젝트로 디자인과 피그마에 대해 본격적으로 공부하고 활용할 수 있어서 너무 좋았습니다. 그리고 디자인을 초반에 빨리 완성하려고 노력한 시간에 비례해 재미를 많이 느꼈고, 저에 대해 알아가는 경험을 하나 더 추가한 것 같아 뿌듯했습니다. 또 기획을 하는 과정에서도 제가 놓쳤던 부분들을 배울 수 있어 좋았고, 실제로 기획하고 디자인하는 과정 자체가 하나의 소중한 경험이 된 것 같습니당.',
      ],
      project: ['🗂️포럼 - 기획, 디자인', '🧑‍💻메인웹 v2 - 기획, 디자인'],
    },
    {
      img: '김준하.png',
      name: '김준하',
      title: '안녕하세요',
      introduction: 'typescript를 좋아하는 학생입니다!',
      review: ['사람들과 같이 코딩하는 일은 어려운 대신 더 많은 것을 배울 수 있어서 좋았습니다'],
      project: ['🎮배틀글라운드 - 백엔드'],
    },
    {
      img: '임준용.png',
      name: '임준용',
      title: '새싹반 개발자',
      introduction: '안녕하세요! QUIPU-DEV 1기 컴퓨터과학부 재학중인 임준용입니다!',
      review: [
        '학기 중에 프론트엔드 과제를 수행하면서 공부했던 것들을 방학 때 프로젝트에서 활용할 수 있어 좋았고, 프론트엔드 총괄 팀장님께서 학기 중, 방학 때 모두 열정적으로 이끌어 주셔서 원활히 진행되었던 것 같습니다.',
        '팀원들과 협업하며 퍼블리싱(디자인)부터 프론트엔드(웹) 개발 및 백엔드 연동까지 해서 만든 반응형 웹페이지를 배포해 추후 많은 사용자들이 사용할 수 있다는 사실이 뜻 깊은 것 같습니다.',
      ],
      project: ['🛠️백오피스 - 프론트엔드', '📬메인웹 v2 - 프론트엔드', '🗂️포럼 - 프론트엔드'],
    },
    {
      img: '윤서현.png',
      name: '윤서현',
      title: '컴맹',
      introduction: '안녕하세요, 전전컴 재학중인 윤서현입니다.',
      review: [
        '이번 웹디자인 활동을 통해 많은 것을 배웠고, 그중에서도 서로 소통하면서 프로젝트를 진행하는 과정 자체가 가장 가치있던 경험이었습니다.',
      ],
      project: ['🗂️포럼 - 디자인', '🎮배틀글라운드 - 디자인'],
    },
    {
      img: '이예나.png',
      name: '이예나',
      title: '시켜줘 개발자',
      introduction:
        '안녕하세요! 저는 전자전기컴퓨터공학부 20학번 이예나라고 합니다. 퀴푸의 회장 겸 QUIPU DEV의 개발 PM, 프론트엔드의 팀장을 맡고 있습니다. 퀴푸의 홈페이지가 생겼으면 좋겠다는 생각에 개발을 시작했고 기획, 디자인, FE와 BE 개발 전반적인 프로세스에 관심을 가지며 재밌게 개발하고 있습니다!',
      review: [
        '처음으로 많은 사람들과 협업하여 개발을 할 수 있어서 너무 재밌었습니다! 든든한 개발 파트너를 많이 만날 수 있어서 너무 좋았고, 자유롭게 아이디어를 내며 하고 싶은 것을 제약없이 도전해볼 수 있다는 점이 퀴푸의 가장 큰 장점이라고 생각합니다! 재미있게 같이 개발할 파트너를 찾는다면 퀴푸 개발팀에 오세요!',
      ],
      project: [
        '🛠️백오피스 - 기획',
        '🗂️포럼 - 기획, 프론트엔드',
        '🎮배틀글라운드 - 기획, 디자인, 프론트엔드',
        '💻메인웹 v2 - 기획, 디자인, 프론트엔드',
        '🌈룰렛 이벤트 - 기획, 디자인, 프론트엔드',
      ],
    },
    {
      img: '이제민.jpeg',
      name: '이제민',
      title: '코딩할 땐 꼭 카페인을 챙기세요',
      introduction: '안녕하세요, 전전컴 재학중인 20학번 이제민입니다.',
      review: [
        '백오피스 - 백오피스는 개인적으로 새로운 경험을 할 수 있도록 해준 프로젝트였습니다. 타 프로젝트들은 이미 잘 만들어진 비즈니스 모델들이 존재하여, 그런 모델들과 최대한 유사하도록 만드는 것을 목표로 진행하는 경우가 많았는데 백오피스는 정말 저희가 개발팀 활동을 하면서 불편하다고 느꼈던 점들을 해결하려고 기능들을 구현했습니다. 그러다 보니 이상적인 형태라는 개념이 없어져 자주 수정을 하게 된 것 같습니다. 처음엔 필요하다고 느꼈던 기능을 개발하다가 꼭 필요할까 라는 생각이 들어 없애기도 하고, 별 생각이 없어 미뤄두었던 기능이 필요할 수 있겠다 생각이 들어 개발하기도 하는 등 많은 생각을 하며 개발할 수 있게 해준 프로젝트였습니다.',
        '포럼 - 백오피스와는 반대로, 가장 비즈니스 모델 형태를 맞추어 개발하려고 노력한 프로그램이었습니다. 실무에선 어떤 방식으로 구현할까? 를 계속 생각하면서 개발을 했던 기억이 있습니다. 그에 따라 최대한 많은 사람들이 사용하는 라이브러리, 프레임워크를 사용하기를 목표로 하면서 개발을 진행해 보았습니다. 백엔드 프로젝트 중의 대표적인 주제 중 하나인 게시판 웹을 만드는 경험을 해서 좋았습니다.',
        '메인 - 오류가 없이 구현하기만을 목표로 했던 저번 개발과 다르게, 이번 개발에선 코드의 효율성, 추후 확장성을 고려하면서 만들었습니다. 조금 더 공부를 하고 경험이 쌓이고 난 뒤 제가 짠 코드가 얼마나 별로였는지 느껴져, 부끄러워 하면서 개발을 했습니다. 개인적으로 프로젝트 경험이 얼마나 중요한 지 느끼게 되는 순간이었고, 나중에 인수인계를 쉽게 할 수 있도록 노력해서 만들어 봤습니다.',
      ],
      project: [
        '🛠️백오피스 - 기획, 백엔드',
        '🗂️포럼 - 기획, 백엔드',
        '💻메인웹 v2 - 백엔드',
        '🌈룰렛 이벤트 - 백엔드',
      ],
    },
    {
      img: '최진영.png',
      name: '최진영',
      title: 'ʕᵔᴥᵔʔ',
      introduction: '안녕하세요 최진영입니다.',
      review: ['협력해서 큰 결과물을 만들어 나가는 것이 뿌듯했습니다!'],
      project: ['🗂️포럼 - 프론트엔드'],
    },
    {
      img: '김준호.png',
      name: '김준호',
      title: '퀴.푸.조.아',
      introduction: '어쩌다 개발 부원 됐습니다.',
      review: ['힘들어요.어려워요.재밌어요.보람차요.2학기에 또 할까'],
      project: ['🛠️백오피스 - 프론트엔드', '🎮배틀글라운드 - 프론트엔드'],
    },
    {
      img: '박주현.png',
      name: '박주현',
      title: '또 하나의 인연 quipu!',
      introduction: '안녕하세요 메인웹과 포럼웹 프론트담당 박주현입니다.',
      review: [
        '책 및 강의로 이론만 공부하는 것과 실전은 많이 다르다는 것을 배웠습니다. 그리고 노베이스라도 본인의 노력으로 충분히 극복할 수 있다는 것도 알게되었어요. 돌아보면 스스로에 대해 아쉬운 부분들이 있었는데 이 부분들은 다음학기를 통해 채워가면서 발전해갔으면 좋겠습니다~',
      ],
      project: ['🗂️포럼 - 프론트엔드', '🧑‍💻메인웹 v2 - 프론트엔드'],
    },
    {
      img: '정지훈.png',
      name: '정지훈',
      title: '웹개발 재밌어요!',
      introduction: '안녕하세요 QUIPU - DEV 1기에서 활동한 24학번 정지훈입니다.',
      review: [
        '코딩관련 지식 하나 없이 제로베이스로 프로젝트에 참여하게 되었는데 보다 많은 것들에 대해 배울 수 있었고 프로젝트 기간동안 시간을 보람차게 보낸 것 같아 좋았습니다.',
      ],
      project: ['🎮배틀글라운드 - 프론트엔드'],
    },
  ],
];
